import { Box, Container, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Line from "../components/DefinitionComponents/Complete-Components/Line";
import InViewTrigger from "../components/DefinitionComponents/Utility/UX/InViewTrigger";
import GImage from "../components/DefinitionComponents/GImage";
import Layout from "../components/layout/Layout";
import FadeInAnimation from "../components/DefinitionComponents/Animation-Components/FadeInAnimation";
import AnimatedText from "../components/DefinitionComponents/Complete-Components/AnimatedText";
import ImgTextModule from "../components/DefinitionComponents/ImageTextModule";

const Index = () => {
	const { setColorMode } = useColorMode();
	useEffect(() => {
		setColorMode("light");
	});
	return (
		<Layout
			title={"Privacy/Datenschutz"}
			description={
				"Privacy/Datenschutz"
			}>
			<ImgTextModule
				src={"14mini.mp4"}
				delay={0.3}
				duration={0.5}
				threshold={0.3}
				initialY={10}
				initialX={10}
				video={true}
				h="85vh"
				mediaOpacity={0.7}
				bgColor="brand.gray.100">
				<Container variant="layoutContainer" mt={8} px={8} pt={24}>
					<Box pb={24}>
						<GImage
							width="10%"
							src="dfn-logo-asia-white-small.svg"
						/>

						<InViewTrigger threshold={0.1} triggerOnce={false}>
							{(inView) => (
								<Line
									maxW="50%"
									duration={1}
									delay={0}
									inView={inView}
									bg={"brand.green.primary"}
									h={"1px"}
									my={2}
								/>
							)}
						</InViewTrigger>
						<AnimatedText
							tag={"h2"}
							mb={4}
							lineHeight="undercut"
							text={"Datenschutzerklärung / Privacy"}
							color={"brand.green.primary"}
							lineHeight="1"
							size={{ base: "3xl", md: "3xl", lg: "7xl" }}
							maxW={"4xl"}
							threshold={0.2}
							triggerOnce={true}
							staggerChildren={0.02}
							id={"dfn1-titlesub1"}
						/>
						<AnimatedText
							tag={"h3"}
							mb={4}
							lineHeight="undercut"
							text={"Definition Digital Products Development + Platforms GmbH"}
							color={"brand.gray.almostwhite"}
							lineHeight="1"
							size={{ base: "3xl", md: "3xl", lg: "5xl" }}
							maxW={"4xl"}
							threshold={0.2}
							triggerOnce={true}
							staggerChildren={0.02}
							id={"dfn1-titlesub1"}
						/>
					</Box>
				</Container>
			</ImgTextModule>
			<Container variant="layoutContainer" py={8} pt={16} px={8}>
				<AnimatedText
					tag={"h3"}
					mb={4}
					lineHeight="undercut"
					text={"Datenschutzerklärung"}
					color={"brand.gray.300"}
					lineHeight="1"
					size={{ base: "3xl", md: "3xl", lg: "5xl" }}
					maxW={"4xl"}
					threshold={0.2}
					triggerOnce={true}
					staggerChildren={0.02}
					id={"dfn1-titlesub1"}
				/>
				<div>		
<p>Wir freuen uns über Ihr Interesse an unserer Website, den verwendeten Techniken welche diese nutzt. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie daher ausführlich über den Umgang mit Ihren Daten.</p>
<p>Erhebung, Verarbeitung und Nutzung personenbezogener Daten<br/>
Sie können unsere Seite besuchen, ohne Angaben zu Ihrer Person zu machen. Wir speichern – auch wenn der Besuch ggf. über Newsletterlinks erfolgt – lediglich Zugriffsdaten ohne Personenbezug wie z.B.</p>
<p>den Namen Ihres Internet Service Providers – die Seite, von der aus Sie uns besuchen<br/>
den Namen der angeforderten Datei<br/>
Diese Daten werden ausschließlich zur Verbesserung unseres Angebotes ausgewertet und erlauben keinen Rückschluss auf Ihre Person.</p>
<p>Wir erheben, speichern und verarbeiten Ihre Daten für die Bestellabwicklung Ihres Kaufs und eventuelle spätere Gewährleistungsabwicklung sowie für Werbezwecke. Personenbezogene Daten werden erhoben, wenn Sie uns diese im Rahmen Ihrer Warenbestellung oder bei Eröffnung eines Kundenkontos oder bei der Registrierung zum Newsletter freiwillig mitteilen.</p>
<p>Widerspruchsrecht zur Datenverwendung für Zwecke der Werbung oder der Markt- oder Meinungsforschung<br/>
Selbstverständlich können Sie jederzeit einer weiteren Verwendung Ihrer Daten zu Werbe- und Marketingzwecken – auch soweit diese rechtlich ohne Ihre Einwilligung zulässig ist (z.B. Postwerbung) – durch eine einfache Mitteilung an uns widersprechen, ohne dass Ihnen hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen. Diesem Hinweis kommt keine Funktion als Einwilligung Ihrerseits in eine Nutzung Ihrer Daten für Werbezwecke zu.</p>
<p>Eine Weitergabe Ihrer personenbezogenen Daten erfolgt an von uns im Rahmen der Ausführung des Auftrags eingesetzte Dienstleister (Transporteur, Logistiker, Banken).</p>
<p><strong>Auskunftsrecht</strong><br/>
Nach dem Bundesdatenschutzgesetz haben Sie ein Recht auf unentgeltliche Auskünfte über Ihre gespeicherten Daten sowie ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.</p>
<p><strong>Verwendung von Cookies</strong><br/>
Auf unserer Webseite setzen wir sog. Cookies ein. Cookies sind kleine Dateien, die auf Ihrem Datenträger gespeichert werden und die bestimmte Einstellungen und Daten zum Austausch mit unserem System über Ihren Browser speichern. Diese Speicherung hilft uns, die Webseite für Sie entsprechend zu gestalten und erleichtert Ihnen die Nutzung, indem beispielsweise bestimmte Eingaben von Ihnen so gespeichert werden, dass Sie sie nicht ständig wiederholen müssen. Ihr Browser erlaubt Ihnen einschränkende Einstellungen zur Nutzung von Cookies, wobei diese dazu führen können, dass unser Angebot für Sie nicht mehr bzw. eingeschränkt funktioniert. Cookies können dort auch gelöscht werden.</p>
<p><strong>Sitzungs-Cookies</strong><br/>
Die Sitzungscookies (auch Sessioncookies genannt) werden nach dem Schließen Ihres Browsers wieder gelöscht.</p>
<p><strong>Langzeit-Cookies</strong><br/>
Es werden bei uns auch Cookies eingesetzt, die auf der Festplatte bei Ihnen verbleiben. Die Verfallszeit ist für Ihre Bequemlichkeit auf ein Datum in der Zukunft eingestellt. Bei einem weiteren Besuch wird dann automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen Sie bevorzugen.</p>
<p>&nbsp;</p>
<p><strong>Ansprechpartner für Datenschutz</strong><br/>
Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogener Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von Daten sowie Widerruf erteilter</p>
<p>Einwilligungen wenden Sie sich bitte an:</p>
<p>Christoph Gerken<br/>
Am Hillernsen Hamm 51<br/>
26441 Jever</p>
<p>e-Mail: hello@definition.group</p>
<p><strong>Widerruf von Einwilligungen</strong><br/>
Die von Ihnen auf unserem Internetauftritt eventuell erteilten Einwilligungen (beispielsweise für einen E-Mail-Newsletterbezug) haben wir jeweils protokolliert und gespeichert. Sie können den Inhalt jeder dieser Einwilligungen jederzeit bei uns abfragen. Bitte wenden Sie sich hierfür per E- Mail an unseren oben genannten Ansprechpartner für den Datenschutz. Wir möchten Sie darauf hinweisen, dass Sie erteilte Einwilligungen jederzeit mit Wirkung für die Zukunft widerrufen können.</p>
					</div>
			</Container>
		</Layout>
	);
};

export default Index;
